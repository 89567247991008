import React, { Component } from "react";
import { Modal, Row, Col } from "antd";

class SideBarSupportBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  setIsModalOpen = (isOpen) => {
    this.setState({ isModalOpen: isOpen });
  };

  render() {
    const { item, appProperties } = this.props;
    const { isModalOpen } = this.state;
    const iframeUrl = item?.iframeUrl + `?Name=${appProperties?.userName}&Email=${appProperties?.currentUserEmail}`;

    return (
      <>
        <Modal
          title=" "
          centered
          open={isModalOpen}
          onOk={() => this.setIsModalOpen(false)}
          onCancel={() => this.setIsModalOpen(false)}
          width={1000}
          footer={null}
        >
          <div>{iframeUrl && <iframe className="iframe-popup" src={iframeUrl} frameBorder="0" allowFullScreen title="Schedule"></iframe>}</div>
        </Modal>

        <div style={{ textDecoration: "none", backgroundColor: "#F5F6F8" }} className="mb-3" onClick={() => this.setIsModalOpen(true)}>
          <Row align="middle">
            <Col span={6} style={{ textAlign: "center", borderRadius: "50px", backgroundColor: "#FFFFFF" }}>
              {item?.icon}
            </Col>
            <Col span={13}>
              <Row className="ps-2">
                <Col span={24} className="interFontWeightMedium" style={{ lineHeight: 1.5 }}>
                  {item?.label}
                </Col>
                <Col span={18} style={{ lineHeight: 1, fontSize: 12, color: "#9CA3AF" }}>
                  {item.supportBody}
                </Col>
              </Row>
            </Col>
            <Col span={5} className="actionIconsSprite right-arrowSidebar"></Col>
          </Row>
        </div>
      </>
    );
  }
}

export default SideBarSupportBooking;
