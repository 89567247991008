import { localStorage, isOmniAccount, urlParams, APP_URL } from "./../constants/AppConstants";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}
export let TeamsController = {
  service: "Teams",
  isCustomModuleNeeded: true,
  isLogoutNeeded: true,
  isPhoneConfigurationEnabled: true,
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "OMNI_GOLD",
    topPlanId: "omni_gold-USD-Monthly",
    totalUsers: 3,
  },
  sideBarExtend: {
    usersSideBar: true,
    customModuleTab: true,
    isAutomationEnabled: false,
  },
  workFlowIconInTemplates: true,

  getCustomAuthUrl: function (appProperties) {
    return new Promise((resolve, reject) => {
      var domain = APP_URL;
      if (domain.includes("localhost")) domain = "https://8fb4-2401-4900-8827-6e30-9974-863a-aa96-bd44.ngrok-free.app";
      const redirectUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=e04b1234-37f2-44f7-9575-b39259c5a102&redirect_uri=${domain}/api/v1/teams/redirect&state=05ef9f6b-750c-4653-a0d5-1e704137b2fb:::${appProperties?.osyncId}&scope=https://api.interfaces.records.teams.microsoft.com/user_impersonation offline_access&access_type=offline&prompt=select_account`;
      resolve(redirectUrl);
    });
  },

  checkIsAlreadyInstalled: function (fetchData) {
    return new Promise((resolve, reject) => {
      var integIdApiKey = "integId".trim();
      var hashApiKey = "hash".trim();
      TeamsController.get(integIdApiKey).then(function (integId) {
        TeamsController.get(hashApiKey).then(function (hash) {
          TeamsController.get("osyncId").then(function (osyncIdFromStorage) {
            var extensionData = {
              hash: hash,
              integId: integId,
              osyncId: osyncIdFromStorage,
            };

            console.log("checkIsAlreadyInstalled default extensionData >>>>>>>>>>>>", extensionData);
            resolve(extensionData);
          });
        });
      });
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var leftServiceName = urlParams.get("service");
      var allUrlParams = getAllUrlParams();
      var redirectSendSMSFromModule = domain + "/persons/list";
      console.log("allUrlParams>>>>", allUrlParams);
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&leftServiceName=${leftServiceName}&urlParams=${allUrlParams}&redirectSendSMSFromModule=${redirectSendSMSFromModule}`;

      console.log("hrefUrl >>>>>>>>>>>>>>>>>>>.", hrefUrl);
      resolve(hrefUrl);
    });
  },
  save: function (data) {
    return new Promise((resolve, reject) => {
      data?.map((key, val) => {
        if (isOmniAccount) {
          key = "Omni_" + key;
        }
        if (val) {
          localStorage.setItem(key, val);
        }
        return null;
      });
      resolve();
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {});
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      if (isOmniAccount) {
        key = "Omni_" + key;
      }
      resolve(localStorage.getItem(key));
    });
  },
  delete: function (key) {
    return new Promise((resolve, reject) => {
      if (isOmniAccount) {
        key = "Omni_" + key;
      }
      if (localStorage.getItem(key)) {
        resolve(localStorage.removeItem(key));
      } else {
        reject();
      }
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      const keysToRemove = ["loggingPreference", "recordOwner"];
      const filterSettings = (obj) => {
        if (Array.isArray(obj)) {
          return obj.map((item) => filterSettings(item)).filter((item) => item && !keysToRemove.includes(item.key));
        }
        if (typeof obj === "object" && obj !== null) {
          const newObj = { ...obj };
          if (newObj.additionalFields) {
            newObj.additionalFields = filterSettings(newObj.additionalFields);
          }
          return newObj;
        }
        return obj;
      };

      const filteredArray = filterSettings(settingsObj);
      resolve(filteredArray);
    });
  },
  defaultSettingValue: {
    emailNotification: {
      sendEmail: true,
      accountOwner: true,
    },
    userNotification: "true",
  },
  getAssociatedObjectId: function () {
    return new Promise((resolve, reject) => {
      resolve(
        "AAMkAGQ3MTc2OGQ5LTc3NjMtNDUyOC1hNzUxLTdiYjYyOTI1M2JmNQBGAAAAAADL8RtjU_hBTLKi1pzzHg4rBwBZKw_E8YJITbaotJiEcZ6oAAAcVNm_AABZKw_E8YJITbaotJiEcZ6oAAAcVRiAAAA="
      );
    });
  },
  getAssociatedObjectType: function () {
    return new Promise((resolve, reject) => {
      resolve("Contacts");
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      var integIdApiKey = "integId".trim();

      TeamsController.get(integIdApiKey)
        .then(function (integId) {
          if (integId) {
            resolve(integId);
          } else {
            reject();
          }
        })
        .catch(function (err) {
          console.log("getAssociatedIntegId Catch", err);
          reject();
        });
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      var hashApiKey = "hash".trim();

      TeamsController.get(hashApiKey).then(function (hash) {
        if (hash) {
          resolve(hash);
        }
      });
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    TeamsController.save(dataObj);
  },
  getWebhookContent: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  doCompleteInstallationProcess: function (integId, hash) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  init: function () {
    return new Promise((resolve, reject) => {
      console.log("INIT ::");
      try {
      } catch (error) {
        console.log(" error happend for pipedrive sms init ", error);
      }
      resolve();
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    return new Promise((resolve, reject) => {
      var redirectUrl = `leftServiceId=${appProperties.leftServiceId}&service=teams&domain=org${appProperties.companyId}&userId=${appProperties.userId}&companyId=${appProperties.companyId}`;
      resolve(redirectUrl);
    });
  },
};
