import React, { useContext, useEffect, useState } from "react";
import { Radio, Switch, Button, Typography, Space, Row, Col, message, Skeleton } from "antd";
import "../../assets/css/setting.css";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";
import EmailComponent from "./EmailComponent";
import CheckBoxField from "./CheckBoxField";
import { getSettingsConfig } from "./settingConfig";
import { CommonModal, NoChannelPresent } from "../utils/CommonVessels";
import { useBlocker } from "react-router-dom";
import { urlParams } from "../../constants/AppConstants";
import CountriesWithCountryCode from "./CountriesWithCountryCode";
const { Title } = Typography;

const GeneralSettings = () => {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [settingsValue, setSettingsValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [apiBody, setApiBody] = useState([]);
  const [errorObj, setErrorObj] = useState(null);
  const [showSaveSetting, setShowSaveSetting] = useState(false);
  const [settingBtnLoading, setSettingBtnLoading] = useState(false);
  const [settingPageLoaded, setSettingPageLoaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [settingConfiguration, setSettingConfiguration] = useState([]);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => showSaveSetting && currentLocation.pathname !== nextLocation.pathname);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Changes saved successfully",
    });
  };

  useEffect(() => {
    let settingConfigObj = getSettingsConfig(setSettingsValue, setApiBody, appProperties);
    appProperties?.controller?.getSettingPreference(settingConfigObj).then(function (settingsObj) {
      setSettingConfiguration(settingsObj);
    });
  }, [appProperties]);

  useEffect(() => {
    fetchData("settings", "GET", null, appProperties)
      .then(function (response) {
        response = JSON.parse(response);
        let respToObj = response?.data?.data?.reduce((acc, obj) => {
          let keyName = obj?.settingName_leftServiceId?.split("_")[0];

          acc[keyName] = keyName.includes("emailNotification")
            ? {
                ...obj,
                sendEmail: obj?.settingValue === "true",
                customEmail: obj?.customEmail === "true",
                accountOwner: obj?.accountOwner === "true",
                recordOwner: obj?.recordOwner === "true",
              }
            : obj?.settingValue;
          return acc;
        }, {});

        let defaultValue = appProperties.controller.defaultSettingValue;
        if (respToObj) {
          defaultValue = { ...defaultValue, ...respToObj };
          setSettingsValue(defaultValue);
          setInitialValue(defaultValue);
        } else {
          setSettingsValue(defaultValue);
          setInitialValue(defaultValue);
        }
        setSettingPageLoaded(true);
      })
      .catch((e) => {
        setSettingPageLoaded(true);
      });
  }, [appProperties, fetchData]);

  const handleSave = () => {
    setErrorObj(null);
    const settingsArray = [];
    if (appProperties?.leftServiceId) {
      Object.keys(settingsValue).forEach((key) => {
        const settingName = key;
        let settingValue = settingsValue[key];
        let settingsEntity = {
          settingName_leftServiceId: settingName,
          settingValue: settingValue,
        };
        if (key === "emailNotification") {
          settingsEntity.settingValue = settingValue?.sendEmail;
          settingsEntity.recordOwner = settingValue?.recordOwner;
          settingsEntity.accountOwner = settingValue?.accountOwner;
          settingsEntity.customEmail = settingValue?.customEmail;
          settingsEntity.customEmailList = settingValue?.customEmailList;
        }
        if (key === "defaultCountryCode") {
          settingsEntity.countryCode = settingsValue?.countryCode;
        }
        if (apiBody.includes(settingsEntity?.settingName_leftServiceId)) {
          if (key !== "countryCode") {
            settingsArray.push(settingsEntity);
          }
        }
      });

      let emailObj = settingsArray.find((obj) => obj.settingName_leftServiceId === "emailNotification");

      if (
        emailObj?.settingValue &&
        !emailObj?.recordOwner &&
        !emailObj?.accountOwner &&
        (!emailObj?.customEmailList || emailObj?.customEmailList?.length < 1)
      ) {
        setErrorObj({
          "Email notification": "Please select at least one email address to receive email notifications.",
        });
        return;
      }
      if (emailObj?.settingValue && emailObj?.customEmail && (!emailObj?.customEmailList || emailObj?.customEmailList?.length < 1)) {
        setErrorObj({
          "Email notification": "Please add at least one email address to receive email notifications.",
        });
        return;
      }
      setSettingBtnLoading(true);
      fetchData(
        `settings?leftServiceId=${appProperties?.leftServiceId}&integId=${appProperties?.randomIntegId}`,
        "POST",
        { data: settingsArray },
        appProperties
      ).then(function (response) {
        try {
          response = JSON.parse(response);
          if (response?.data?.status) {
            setShowSaveSetting(false);
            setApiBody([]);
            success();
            setInitialValue(settingsValue);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setSettingBtnLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    const settingsValueString = JSON.stringify(settingsValue);
    const initialValueString = JSON.stringify(initialValue);
    if (settingsValueString !== initialValueString) {
      setShowSaveSetting(true);
    } else {
      setShowSaveSetting(false);
      setApiBody([]);
      setErrorObj(null);
    }
  }, [settingsValue, initialValue]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showSaveSetting) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showSaveSetting]);

  const handleCloseModal = () => {
    blocker.reset();
  };
  const handleDiscard = () => {
    setSettingsValue(initialValue);
    blocker.proceed();
  };

  const modalBody = (
    <>
      <div>
        <div className="ms-2">
          <h5 className="hs-color-dark-blue">Unsaved changes</h5>
          <div className="hs-color-dark-blue">Do you want to save or discard changes?</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small w-100 h-45" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button className="hs-btn-small ms-3 mt-2 row-reverse d-inline-block w-100 h-45" onClick={handleDiscard}>
          Discard
        </Button>
      </div>
    </>
  );

  return (
    <>
      {contextHolder}
      {appProperties?.serviceInstalled ? (
        <>
          <CommonModal
            width={350}
            body={modalBody}
            open={blocker.state === "blocked"}
            doOnClose={handleCloseModal}
            cancelButtonProps={false}
            okButtonProps={false}
            modalCloseIcon={true}
            header={<div style={{ cursor: "default" }} className="actionIconsSpriteForSideBar unsaved-modal-icon" />}
          />
          {settingPageLoaded ? (
            <Space className="m-5 mt-0" style={{ padding: "20px" }}>
              <div>
                {settingsValue && (
                  <div>
                    <div className="d-flex h-45 justify-content-end">
                      {showSaveSetting && (
                        <Button loading={settingBtnLoading} onClick={handleSave} className="hs-btn-small">
                          Save changes
                        </Button>
                      )}
                    </div>
                    {settingConfiguration.map((obj) => (
                      <Row key={obj.key + "row"} style={{ marginBottom: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                          <Title className="hs-color-dark-blue hs-fs-14" level={4}>
                            {obj.title}
                          </Title>
                          <p className="hs-fs-12 hs-color-dark-grey ">{obj.description}</p>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17} xl={17} className="d-flex h-100">
                          <div className="hs-color-dark-blue m-3 p-3 me-1 w-100 rounded hs-bg-white ">
                            <div className="w-100">
                              {obj.type === "radio" && (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="hs-color-dark-blue">{obj?.label}</div>
                                  <div className="mb-0" name={obj.key}>
                                    <Radio.Group value={settingsValue?.[obj?.key]}>
                                      {obj.options.map((option) => (
                                        <Radio
                                          className="setting-radio-btn hs-color-dark-grey"
                                          key={option.value}
                                          value={option.value}
                                          onChange={(e) => {
                                            obj?.onchange(obj?.key, e.target.value);
                                          }}
                                        >
                                          {option.label}
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                  </div>
                                </div>
                              )}

                              {obj.type === "select" && (
                                <div className="w-50">
                                  <div className="hs-color-dark-blue hs-fs-12">{obj?.label}</div>
                                  <div className="mb-0 mt-1" name={obj.key}>
                                    <CountriesWithCountryCode
                                      parentKey="defaultCountryCode"
                                      setSettingsValue={setSettingsValue}
                                      setApiBody={setApiBody}
                                      value={settingsValue.defaultCountryCode}
                                    />
                                  </div>
                                  <div className="hs-color-dark-blue hs-fs-12">{obj?.tag}</div>
                                </div>
                              )}
                              {obj.type === "switch" && (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>{obj?.label}</div>
                                  <div className="mb-0" name={obj.key}>
                                    <Switch
                                      className="addPhoneSwitch"
                                      onChange={(e) => {
                                        obj?.onchange(obj?.parentKey, obj?.key, e);
                                      }}
                                      checked={
                                        obj?.parentKey.includes("emailNotification")
                                          ? settingsValue?.emailNotification?.[obj?.key]
                                          : settingsValue?.[obj?.key]
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {settingsValue?.[obj?.parentKey]?.[obj?.key] && (
                                <>
                                  {obj.additionalFields &&
                                    obj.additionalFields.map((field, index) => (
                                      <div className="m-1 mt-2" key={field?.type + index}>
                                        {field.type === "email" && settingsValue?.emailNotification?.customEmail ? (
                                          <EmailComponent
                                            parentKey={obj?.parentKey}
                                            settingsValue={settingsValue}
                                            field={field}
                                            setErrorObj={setErrorObj}
                                          />
                                        ) : field?.type === "checkbox" ? (
                                          <CheckBoxField
                                            parentKey={obj?.parentKey}
                                            setErrorObj={setErrorObj}
                                            field={field}
                                            settingValue={settingsValue}
                                          />
                                        ) : (
                                          <p className="m-0 hs-fs-12 hs-color-dark-grey">{field?.text}</p>
                                        )}
                                      </div>
                                    ))}
                                </>
                              )}
                            </div>
                            {errorObj?.[obj?.title] && <div className="hs-fs-12 hs-color-red">{errorObj[obj?.title]} </div>}
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </div>
            </Space>
          ) : (
            <div className="m-5 p-3">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="d-flex justify-content-between align-items-center">
                  <Skeleton key={"skeleton" + index} className="m-3" active size="small" />

                  <Skeleton.Input active size={"large"} />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <NoChannelPresent />
      )}
    </>
  );
};

export default GeneralSettings;
