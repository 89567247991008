import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./../../context/AppContext";

import { Col, Image, Button, Input, Modal, Row } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./../../assets/css/authorizePage.css";

import useHttp from "../../hooks/useHttp";
import { CommonLoadingV2, handleBuyNumberClick } from "../utils/CommonVessels";
import { getRandomNumber } from "../utils/commonUtils";
import { failureNotification, successNotification } from "../utils/CommonNotifications";
import { useNavigate } from "react-router-dom";

function importAllImage(r) {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
    return null;
  });

  return images;
}
const images = importAllImage(require.context("./../../assets/images/", false, /\.(png|jpe?g|svg)$/));

// function importAllVideo(r) {
//   let helpVideo = {};
//   r.keys().map((item) => {
//     helpVideo[item.replace("./", "")] = r(item);
//     return null;
//   });

//   return helpVideo;
// }
// const helpVideoFunct = importAllVideo(
//   require.context("./../../assets/video/", false, /\.mp4$/)
// );

const labelVarialbles = {
  twilio: {
    username: "Account SID",
    password: "Auth Token",
  },
  twilio_whatsapp: {
    username: "Account Sid",
    password: "Account Token",
  },
  ClickSend: {
    username: "Username",
    password: "Password",
  },
  plivo: {
    username: "Auth Id",
    password: "Auth Token",
  },
  vonage: {
    username: "Account name",
    password: "Account secret",
  },
  burstsms: {
    username: "Api key",
    password: "Api secret",
  },
  telnyx: {
    username: "Api key",
    password: "Api secret",
  },
};

function AuthorizePage({ rightServiceObj, setRightServiceObj }) {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [appLogo, setAppLogo] = useState("");
  const [rightServiceTitle, setRightServiceTitle] = useState("");
  const [authorizeType, setAuthorizeType] = useState("");
  const [authorizationSuccess, setAuthorizationSuccess] = useState(false);
  const osyncId = appProperties.osyncId;
  const integId = rightServiceObj?.integId;
  const serviceId = rightServiceObj?.serviceId;
  const rightServiceName = appProperties.rightServiceName;
  const propsData = {
    osyncId: osyncId,
    integId: integId,
    serviceId: serviceId,
    rightServiceName: rightServiceName,
    authorized: rightServiceObj?.authorized,
  };
  const { fetchData } = useHttp();

  const authorizationSuccessStatus = (response) => {
    setAuthorizationSuccess(response);
  };

  useEffect(() => {
    if (appProperties?.providerCommonObj) {
      var appLogoName = appProperties?.providerCommonObj?.app + ".png";

      setAppLogo(images[appLogoName]);
      setRightServiceTitle(appProperties?.providerCommonObj?.title);
      setAuthorizeType(appProperties?.providerCommonObj?.AuthorizationType);
    }
  }, [appProperties]);

  useEffect(() => {
    if (authorizationSuccess && integId) {
      var subscriptionId = "";
      const Body = {
        inboundSMS: "true",
        subscriptionId: subscriptionId,
        isLeft: false,
      };
      fetchData(`omessage/completeinstallation?integId=${integId}`, "POST", Body, appProperties)
        .then((response) => {
          setRightServiceObj((prev) => ({
            ...prev,
            authorized: true,
            completeInstallationDone: Math.random(),
          }));
          setAppProperties((prev) => {
            const installedApps = prev && prev.installedApps ? prev.installedApps : [];
            const rightServiceObjExists = rightServiceObj !== undefined && rightServiceObj !== null;
            const existingIndex = rightServiceObjExists
              ? installedApps?.findIndex((app) => app.integProps?.integId === rightServiceObj.integId)
              : -1;
            if (existingIndex !== -1) {
              const updatedApps = [...installedApps];
              updatedApps[existingIndex] = {
                ...updatedApps[existingIndex],
                right: {
                  auth: { authorized: true, url: true },
                  service_id: rightServiceObj?.serviceId,
                  service_name: rightServiceObj?.rightServiceName,
                  service_display_name: rightServiceObj?.service_display_name,
                },
              };
              return {
                ...prev,
                authorizeObj: null,
                authObj: null,
                serviceInstalled: true,
                installedApps: updatedApps,
                randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : rightServiceObj?.integId,
              };
            } else {
              return {
                ...prev,
                authorizeObj: null,
                serviceInstalled: true,
                authObj: null,
                randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : rightServiceObj?.integId,
                installedApps: [
                  ...installedApps,
                  {
                    integProps: rightServiceObj,
                    left: {},
                    right: {
                      auth: { authorized: true, url: true },
                      service_id: rightServiceObj?.serviceId,
                      service_name: rightServiceObj?.rightServiceName,
                      service_display_name: rightServiceObj?.service_display_name,
                    },
                  },
                ],
              };
            }
          });
          const responseData = JSON.parse(response);
          const subscriptionIdResponse = JSON.parse(responseData.data.data);
          var subscriptionId = "";
          if (subscriptionIdResponse?.SMSProvider?.subscriptionId) {
            subscriptionId = subscriptionIdResponse.SMSProvider.subscriptionId;
            console.log(subscriptionId, "subscriptionId while authrize ");
          }
        })
        .catch((err) => {});
    }
  }, [authorizationSuccess]);

  return (
    <div className="authStyle" style={{ justifyContent: "space-between" }}>
      <Col offset={2}>
        <Logo appLogo={appLogo} />
        <Title rightServiceTitle={rightServiceTitle} />
        <Authorize
          setRightServiceObj={setRightServiceObj}
          rightServiceObj={rightServiceObj}
          authorizeType={authorizeType}
          propsData={propsData}
          authorizationSuccess={authorizationSuccessStatus}
          appProperties={appProperties}
          setAppProperties={setAppProperties}
        />
        {rightServiceObj?.isTrialAccount === "true" && (
          <div className="p-3 trialAccount ">
            <ExclamationCircleOutlined style={{ fontSize: 25, color: "#FF9800" }} />
            <h6 style={{ color: "#FF9800", fontWeight: "bolder" }}>You're on a Twilio trial.</h6>
            When using a Twilio trial account, SMS messages are limited to verified numbers only. To access the full range of HelloSend's SMS
            messaging services, you have two options: upgrade your Twilio account or purchase a number from us using the 'Buy Number' option.
          </div>
        )}
      </Col>
    </div>
  );
}

const Logo = React.memo((props) => {
  const { appLogo } = props;
  return (
    <>
      <Image className="pt-3 right-logo" src={appLogo} preview={false} draggable={false} height={50} style={{ width: "auto" }} />
    </>
  );
});

const Title = React.memo((props) => {
  const { rightServiceTitle } = props;
  return (
    <>
      <div id="appTitle" style={{ display: "flex", alignItems: "center" }}>
        {rightServiceTitle}
      </div>
    </>
  );
});
const Authorize = React.memo((props) => {
  const { authorizeType } = props;
  const { setRightServiceObj } = props;
  const { rightServiceObj } = props;
  return (
    <>
      {authorizeType === "auth" ? (
        <AuthTypeAuthorize props={props} rightServiceObj={rightServiceObj} setRightServiceObj={setRightServiceObj} />
      ) : authorizeType === "auth" ? (
        <OauthTypeAuthorize props={props} rightServiceObj={rightServiceObj} setRightServiceObj={setRightServiceObj} />
      ) : (
        <NoAuthCustomFlow props={props} rightServiceObj={rightServiceObj} setRightServiceObj={setRightServiceObj} />
      )}
    </>
  );
});

const NoAuthCustomFlow = (props) => {
  const { propsData } = props.props;
  const { rightServiceObj } = props;
  const { setRightServiceObj } = props;
  const { appProperties } = props.props;
  const { setAppProperties } = props.props;
  const navigate = useNavigate();
  const { fetchData } = useHttp();
  const [isLoadingAuthorizeBtn, setIsLoadingAuthorizeBtn] = useState(false);

  const DoAuthorization = async () => {
    setIsLoadingAuthorizeBtn(true);
    try {
      const redirectUrl = await appProperties?.controller.getCustomAuthUrl(appProperties);

      const authWindow = window.open(redirectUrl, "", "width=700,height=700,left=450,top=100");

      let status = "";
      const receiveMessage = (event) => {
        console.log(":::::: EVENT_RECIEVED ::::::::", event?.data);
        if (event && event.data) {
          status = event.data;
          if (typeof event.data === "string") {
            status = JSON.parse?.(event.data);
          }
          if (status && status?.data?.osyncId) {
            console.log("SUCCESS CUSTOM AUTH");
            setRightServiceObj((prev) => ({
              ...prev,
              customAuth: true,
            }));
          }
        }
      };

      try {
        window.addEventListener("message", receiveMessage);

        return () => {
          authWindow.close();
          window.removeEventListener("message", receiveMessage);
        };
      } catch (error) {
        console.log("error >>>>>>>>>>>>", error);
      }
      successNotification("Service connected successfully! Proceed to add a phone number.");
    } catch (error) {
    } finally {
      setIsLoadingAuthorizeBtn(false);
    }
  };

  const createSubAccountAndAuthorize = async () => {
    if (!rightServiceObj?.authorized) {
      const responseObj = await handleBuyNumberClick(
        appProperties,
        setAppProperties,
        fetchData,
        setIsLoadingAuthorizeBtn,
        () => {},
        navigate,
        "teams_helloSend"
      );
      if (responseObj.helloSendAccountId) {
        setRightServiceObj((prev) => ({
          ...prev,
          integId: responseObj?.integId,
          authorized: true,
        }));
        setAppProperties((prev) => {
          const installedApps = prev && prev.installedApps ? prev.installedApps : [];
          const rightServiceObjExists = rightServiceObj !== undefined && rightServiceObj !== null;
          const existingIndex = rightServiceObjExists
            ? installedApps?.findIndex((app) => app.integProps?.integId === rightServiceObj.integId)
            : -1;
          if (existingIndex !== -1) {
            const updatedApps = [...installedApps];
            updatedApps[existingIndex] = {
              ...updatedApps[existingIndex],
              right: {
                auth: { authorized: true, url: true },
                service_id: rightServiceObj?.serviceId,
                service_name: rightServiceObj?.rightServiceName,
                service_display_name: rightServiceObj?.service_display_name,
              },
            };
            return {
              ...prev,
              authObj: null,
              serviceInstalled: true,
              installedApps: updatedApps,
              randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : rightServiceObj?.integId,
            };
          } else {
            rightServiceObj.integId = responseObj?.integId;
            rightServiceObj.authorized = true;
            return {
              ...prev,
              authObj: null,
              serviceInstalled: true,
              randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : responseObj?.integId,
              installedApps: [
                ...installedApps,
                {
                  integProps: rightServiceObj,
                  left: {},
                  right: {
                    auth: { authorized: true, url: true },
                    service_id: rightServiceObj?.serviceId,
                    service_name: rightServiceObj?.rightServiceName,
                    service_display_name: rightServiceObj?.service_display_name,
                  },
                },
              ],
            };
          }
        });
        DoAuthorization();
      } else {
        failureNotification("Error", "Authorization failed");
      }
    } else if (!rightServiceObj?.customAuth) {
      DoAuthorization();
    }
  };

  return (
    <div className="d-flex align-items-center">
      {propsData?.authorized && rightServiceObj?.customAuth ? (
        <>
          <div
            className="auth-btn d-flex align-items-center"
            style={{ color: "#43BE6F", textDecoration: "none", fontSize: "14px", width: "7rem", cursor: "default" }}
          >
            <div className="actionIconsSprite addAuthorizedIcon"></div>
            <span style={{ marginLeft: "0.25rem" }}>Authorized</span>
          </div>
        </>
      ) : (
        <Button
          type="primary"
          className="auth-btn"
          // disabled={rightServiceObj?.customAuth === undefined || !rightServiceObj?.authorized}
          onClick={createSubAccountAndAuthorize}
          loading={isLoadingAuthorizeBtn}
        >
          Authorize
        </Button>
      )}
    </div>
  );
};
const OauthTypeAuthorize = React.memo((props) => {
  const { authorizationSuccess } = props.props;
  const { propsData } = props.props;
  const { setRightServiceObj } = props;
  const { appProperties } = props.props;
  const { setAppProperties } = props.props;
  const { fetchData } = useHttp();

  const [isLoadingAuthorizeBtn, setIsLoadingAuthorizeBtn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let userIds = urlParams.get("userId");

  const userId = appProperties.userId || userIds;

  async function DoAuthorization() {
    if (propsData?.authorized !== true) {
      let integIdNew = "";
      let redirectUrl = null;
      setIsLoadingAuthorizeBtn(true);
      try {
        if (!propsData.integId && propsData.serviceId && userId) {
          const integrationResp = await fetchData(
            `integration/new?userId=${userId}`,
            "POST",
            {
              left_service_id: appProperties.leftServiceId,
              osync_id: appProperties.osyncId,
              right_service_id: propsData.serviceId,
            },
            appProperties
          );
          const parsedIntegrationResp = JSON.parse(integrationResp);
          integIdNew = parsedIntegrationResp.data.integId;
          redirectUrl = parsedIntegrationResp.data.right.auth.url;
          // setIntegId(parsedIntegrationResp.data.integId);
          if (redirectUrl) {
            let status = "";
            const ringcentralWindow = window.open(redirectUrl, "", "width=700,height=700,left=450,top=100");

            let statusOfRingcentralAuth = "";
            const receiveMessage = (event) => {
              console.log(":::::: MESSAGE_RECIEVED ::::::::");
              if (event && event.data) {
                statusOfRingcentralAuth = event.data;
                if (typeof event.data === "string") {
                  statusOfRingcentralAuth = JSON.parse?.(event.data);
                }
                if (statusOfRingcentralAuth && statusOfRingcentralAuth?.data?.integId) {
                  status = statusOfRingcentralAuth.status;
                  setRightServiceObj((prev) => ({
                    ...prev,
                    integId: statusOfRingcentralAuth.data.integId,
                  }));
                  authorizationSuccess(status);
                }
              }
            };

            try {
              window.addEventListener("message", receiveMessage);

              return () => {
                ringcentralWindow.close();
                window.removeEventListener("message", receiveMessage);
              };
            } catch (error) {
              console.log("error >>>>>>>>>>>>", error);
            }
          }
          setIsLoadingAuthorizeBtn(false);
        }
        const stateValue = propsData.integId ? propsData.integId : integIdNew;

        if (stateValue && redirectUrl === null) {
          await fetchData(`authorization/${stateValue}?userId=${userId}`, "GET", null, appProperties).then((responseData) => {
            const data = JSON.parse(responseData);
            if (data?.data?.right?.auth?.url) {
              let ringcentralAuthUrl = data.data.right.auth.url;
              let status = "";
              const ringcentralWindow = window.open(ringcentralAuthUrl, "", "width=700,height=700,left=450,top=100");

              let statusOfRingcentralAuth = "";
              const receiveMessage = (event) => {
                if (event.data) {
                  statusOfRingcentralAuth = event.data;
                  if (typeof event.data === "string") {
                    try {
                      statusOfRingcentralAuth = JSON.parse?.(event.data);
                    } catch (err) {
                      console.log("ERROR IN PARSING event  DATA ::::  ", event);
                    }
                  }
                  if (statusOfRingcentralAuth && statusOfRingcentralAuth?.data?.integId) {
                    status = statusOfRingcentralAuth.status;
                    setRightServiceObj((prev) => ({
                      ...prev,
                      authorized: true,
                      integId: statusOfRingcentralAuth.data.integId,
                    }));
                    if (status) {
                      authorizationSuccess(getRandomNumber(3));
                    }
                  }
                }
              };
              try {
                window.addEventListener("message", receiveMessage);

                return () => {
                  ringcentralWindow.close();
                  window.removeEventListener("message", receiveMessage);
                };
              } catch (error) {
                console.log("error >>>>>>>>>>>>", error);
              }
            }
          });
        }
      } catch (error) {
        console.log("ERROR", error);
        setIsLoadingAuthorizeBtn(false);
      } finally {
        setIsLoadingAuthorizeBtn(false);
      }
    }
  }
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        {propsData?.authorized !== true && propsData?.serviceId ? (
          <Button id="authorizebuttonringcentral" type="primary" className="auth-btn" onClick={DoAuthorization} loading={isLoadingAuthorizeBtn}>
            {propsData?.authorized === true ? "Authorized" : "Authorize"}
          </Button>
        ) : null}
        {propsData?.authorized === true ? (
          <>
            <Row style={{ border: "2px solid #4CAF50", borderRadius: 12 }} className="p-2 mb-3 me-5">
              <Col span={4} xl={4} md={5}>
                <div className="actionIconsSprite addAuthorizedIcon" style={{ cursor: "default" }}></div>
              </Col>
              <Col span={19} xl={19} md={16} className="pt-2 ms-2">
                <span style={{ fontWeight: 600 }}>Authorization completed!</span>
                <div style={{ fontSize: 12, color: "#374151" }} className="mt-1">
                  Your service has been successfully authorized, and all necessary consents have been obtained.
                </div>
              </Col>
            </Row>
            <div
              className="auth-btn d-flex align-items-center"
              danger
              style={{
                height: 24,
                width: "auto",
                borderRadius: 12,
                textDecoration: "none",
                color: "#E71D36",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <div className="actionIconsSprite revokeIcon"></div>
              <span style={{ marginLeft: "0.25rem", fontSize: 14 }}>Revoke</span>
            </div>
            <DisconnectApplication
              setRightServiceObj={setRightServiceObj}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
              propsData={propsData}
              openModal={openModal}
              setOpenModal={setOpenModal}
              integId={propsData.integId}
            />
          </>
        ) : null}
      </div>
    </div>
  );
});
const AuthTypeAuthorize = React.memo((props) => {
  const { propsData } = props.props;
  const { appProperties } = props.props;
  const { setAppProperties } = props.props;
  const { setRightServiceObj } = props;
  const { rightServiceObj } = props;
  const [isLoadingAuthorizeBtn, setIsLoadingAuthorizeBtn] = useState(false);
  const [emptySid, setEmptySid] = useState(false);
  const [emptyToken, setEmptyToken] = useState(false);
  const [mismatchCreds, setMismatchCreds] = useState(false);
  const [sid, setSid] = useState("");
  const [token, setToken] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const osyncId = propsData.osyncId;
  const serviceId = propsData.serviceId;
  const { fetchData } = useHttp();

  const doAuthorization = async () => {
    if (propsData?.authorized !== true) {
      if (sid === "") {
        setEmptySid(true);
        setIsLoadingAuthorizeBtn(false);
      } else if (token === "") {
        setEmptyToken(true);
      } else if ((sid !== "") & (token !== "")) {
        setIsLoadingAuthorizeBtn(true);
        setMismatchCreds(false);
        let integIdNew = "";
        try {
          if (propsData.integId === null || (propsData.integId === undefined && propsData.serviceId)) {
            const integrationResp = await fetchData(
              `integration/new?userId=${appProperties?.userId}`,
              "POST",
              {
                left_service_id: appProperties.leftServiceId,
                osync_id: osyncId,
                right_service_id: serviceId,
              },
              appProperties
            );
            var parsedIntegrationResp;
            if (integrationResp) {
              parsedIntegrationResp = JSON.parse(integrationResp);
            }
            integIdNew = parsedIntegrationResp.data.integId;
          }
          const stateValue = propsData.integId ? propsData.integId : integIdNew;
          if (stateValue && appProperties.leftServiceId) {
            const apiData = {
              access_token: token,
              state: osyncId + "::" + serviceId + "::" + stateValue + "::false",
              refresh_token: "",
              api_domain: sid,
              twilio_account_sid: sid,
            };
            const saveApiKeyResp = await fetchData("saveApiKey", "POST", apiData, appProperties);
            const data = JSON.parse(saveApiKeyResp);
            if (data?.data?.data?.isValidApiKeys) {
              successNotification("Service connected successfully! Proceed to add a phone number.");
              setRightServiceObj((prev) => ({
                ...prev,
                integId: data?.data?.data?.integId,
                authorized: true,
                isTrialAccount: data?.data?.data?.isTrialAccount,
              }));
              setAppProperties((prev) => {
                const installedApps = prev && prev.installedApps ? prev.installedApps : [];
                const rightServiceObjExists = rightServiceObj !== undefined && rightServiceObj !== null;
                const existingIndex = rightServiceObjExists
                  ? installedApps?.findIndex((app) => app.integProps?.integId === rightServiceObj.integId)
                  : -1;
                if (existingIndex !== -1) {
                  const updatedApps = [...installedApps];
                  updatedApps[existingIndex] = {
                    ...updatedApps[existingIndex],
                    right: {
                      auth: { authorized: true, url: true },
                      service_id: rightServiceObj?.serviceId,
                      service_name: rightServiceObj?.rightServiceName,
                      service_display_name: rightServiceObj?.service_display_name,
                      isTrialAccount: data?.data?.data?.isTrialAccount,
                    },
                  };
                  return {
                    ...prev,
                    authorizeObj: null,
                    authObj: null,
                    serviceInstalled: true,
                    installedApps: updatedApps,
                    randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : rightServiceObj?.integId,
                  };
                } else {
                  rightServiceObj.integId = data?.data?.data?.integId;
                  rightServiceObj.authorized = true;
                  rightServiceObj.isTrialAccount = data?.data?.data?.isTrialAccount;
                  return {
                    ...prev,
                    authObj: null,
                    authorizeObj: null,
                    serviceInstalled: true,
                    randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : data?.data?.data?.integId,
                    installedApps: [
                      ...installedApps,
                      {
                        integProps: rightServiceObj,
                        left: {},
                        right: {
                          auth: { authorized: true, url: true },
                          service_id: rightServiceObj?.serviceId,
                          service_name: rightServiceObj?.rightServiceName,
                          service_display_name: rightServiceObj?.service_display_name,
                          isTrialAccount: data?.data?.data?.isTrialAccount,
                        },
                      },
                    ],
                  };
                }
              });
            } else if (data?.data?.data?.isValidApiKeys === false) {
              setMismatchCreds(true);
            }
          }
        } catch (error) {
          console.log("ERROR", error);
        } finally {
          setIsLoadingAuthorizeBtn(false);
        }
      }
    }
  };
  useEffect(() => {
    if (propsData?.authorized) {
      setToken("*******************");
      setSid("*******************");
    } else {
      setSid("");
      setToken("");
    }
  }, [propsData]);

  return (
    <>
      <div id="auth" className="fontStyle">
        <h6 className="label-authType"> {labelVarialbles[propsData.rightServiceName]?.username}</h6>
        <p id="emptySid" className="invalid" style={{ visibility: emptySid ? "inherit" : "hidden" }}>
          {labelVarialbles[propsData.rightServiceName]?.username} is required.
        </p>
        <Input
          tabIndex={0}
          disabled={propsData?.authorized}
          value={sid}
          onFocus={() => {
            setEmptySid(false);
          }}
          onChange={(value) => {
            setSid(value.target.value);
          }}
          id="sidInput"
          type="text"
          placeholder={`Enter your ${labelVarialbles[propsData.rightServiceName]?.username}`}
          required
          bordered={false}
        ></Input>
        <br />

        <h6 className="label-authType">{labelVarialbles[propsData.rightServiceName]?.password}</h6>
        <p id="emptyToken" className="invalid" style={{ visibility: emptyToken ? "inherit" : "hidden" }}>
          {labelVarialbles[propsData.rightServiceName]?.password} is required.
        </p>
        <Input
          tabIndex={1}
          value={token}
          onFocus={() => {
            setEmptyToken(false);
          }}
          disabled={propsData?.authorized}
          onChange={(value) => {
            setToken(value.target.value);
          }}
          id="tokenInput"
          type="password"
          placeholder={`Enter your ${labelVarialbles[propsData.rightServiceName]?.password}`}
          required
          bordered={false}
        ></Input>
        <br />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {propsData?.authorized !== true && propsData?.serviceId ? (
            <Button
              className="auth-btn"
              type="primary"
              onClick={doAuthorization}
              loading={isLoadingAuthorizeBtn}
              style={{ backgroundColor: "#605BFF" }}
            >
              {propsData?.authorized === true ? "Authorized" : "Authorize"}
            </Button>
          ) : null}
          {propsData?.authorized === true ? (
            <>
              <Row style={{ border: "2px solid #4CAF50", borderRadius: 12 }} className="p-2 mb-3 me-5">
                <Col span={4} xl={4} md={5}>
                  <div className="actionIconsSprite addAuthorizedIcon" style={{ cursor: "default" }}></div>
                </Col>
                <Col span={19} xl={19} md={16} className="pt-2 ms-2">
                  <span style={{ fontWeight: 600 }}>Authorization completed!</span>
                  <div style={{ fontSize: 12, color: "#374151" }} className="mt-1">
                    Your service has been successfully authorized, and all necessary consents have been obtained.
                  </div>
                </Col>
              </Row>
              <div
                className="auth-btn d-flex align-items-center"
                danger
                style={{
                  height: 24,
                  width: "auto",
                  borderRadius: 12,
                  textDecoration: "none",
                  color: "#E71D36",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <div className="actionIconsSprite revokeIcon"></div>
                <span style={{ marginLeft: "0.25rem", fontSize: 14 }}>Revoke</span>
              </div>

              <DisconnectApplication
                appProperties={appProperties}
                setAppProperties={setAppProperties}
                setRightServiceObj={setRightServiceObj}
                propsData={propsData}
                openModal={openModal}
                setOpenModal={setOpenModal}
                integId={propsData.integId}
              />
            </>
          ) : null}
        </div>
        <p id="mismatch" style={{ visibility: mismatchCreds ? "inherit" : "hidden" }}>
          <ExclamationCircleOutlined className="material-icons" />
          <span> </span>
          uh Oh!!...Credentials mismatch
        </p>
      </div>
    </>
  );
});

export default AuthorizePage;

const DisconnectApplication = React.memo((propsData) => {
  const { fetchData } = useHttp();
  const data = propsData.propsData;
  const appProperties = propsData.appProperties;
  const integId = data.integId;
  const setRightServiceObj = propsData.setRightServiceObj;
  const setAppProperties = propsData.setAppProperties;
  const serviceId = data.serviceId;
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDisconnectApplication = () => {
    let apiData = "?left_service=" + false + "&integ_id=" + integId + "&service_id=" + serviceId;
    if (integId && serviceId) {
      setDisconnectLoading(true);
      fetchData("revoke" + apiData, "DELETE", null, appProperties)
        .then(function (resp) {
          console.log(resp);
          propsData.setOpenModal(false);
          setAppProperties((prev) => ({
            ...prev,
            installedApps: prev?.installedApps?.map((obj) => ({
              ...obj,
              right: {
                ...obj?.right,
                auth: {
                  ...obj.right.auth,
                  authorized: obj.integProps.integId === integId ? false : true,
                },
              },
            })),
          }));
          setRightServiceObj((prev) => ({
            ...prev,
            authorized: false,
            isTrialAccount: false,
          }));
          setDisconnectLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
          setDisconnectLoading(false);
        });
    }
  };

  return (
    <>
      <Modal
        open={propsData.openModal}
        className="disconnectModal"
        onCancel={() => {
          propsData.setOpenModal(false);
        }}
        footer={
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Button
              className="w-100  h-45 hs-fs-16 commonGhostButton text-dark"
              style={{ fontWeight: 600 }}
              onClick={() => {
                propsData.setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button className="w-100 h-45 confirmDeleteBtn hs-fs-16" onClick={handleDisconnectApplication}>
              Disconnect
            </Button>
            {loading && <CommonLoadingV2 />}
          </div>
        }
      >
        <Row>
          <Col span={22}>
            <div className="d-flex align-items-center mb-3">
              <div className="actionIconsSprite disConnectIcon me-4"></div>
            </div>
          </Col>
          <Col
            span={2}
            onClick={() => {
              propsData.setOpenModal(false);
            }}
          >
            <div className="m-2 p-1 pt-2 pe-2">
              <div className="actionIconsSprite closeIconBig me-4"></div>
            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1">
          Are you sure you want to disconnect?
        </div>
        <div className="pb-3">
          Disconnecting this service will stop your clients from receiving updates via campaigns, automations, or 1-1 messaging, and you will
          miss incoming messages. Are you sure you want to proceed with disconnecting the service?
        </div>
      </Modal>
    </>
  );
});
