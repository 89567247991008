import { getSettingsConfig } from "./../pages/Setting/settingConfig";
export const urlParams = new URLSearchParams(window.location.search);
export let ParentController = {
  serviceInitialized: false,
  service: "OApps",
  isBannerNeeded: false,
  licenseType: "Omni",
  showSideBar: true,
  showSideBarForcefully: false,
  isConversationViewInSendSMS: true,
  sendBulkTabName: "Send Bulk SMS",
  sendBulkChooseModule: "Choose Module",
  templatePageTableLayout: false,
  manageSubscriptionBtnHide: false,
  sendBulkSMSHelpDocument: false,
  shopIconForShopify: false,
  inboxSendSMS: true,
  executeOmniRelatedDetails: true,
  redirectUrl: false,
  contactNumber: false,
  addUserButton: true,
  associatedLookup: false,
  automationPageHelpLink: "https://help.oapps.xyz/portal/en/kb/articles/automate-sms-in-pipedrive",
  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: false,
    inboxSidebar: false,
    conversationSidebar: true,
    sendSmsSidebar: true,
    sendBulkSmsSidebar: true,
    phoneSidebar: true,
    campaignSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    campaignOverviewSidebar: false,
    workFlowSideBar: true,
    usersSideBar: false,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    isWorkflowtext: "Workflow",
    hideSMSTabsInSideBar: false,
  },
  isCustomModuleNeeded: false,
  isCustomModuleEnabled: false,
  isPhoneConfigurationEnabled: false,
  isOmniLicense: false,
  isHomeTabNeeded: false,
  isTimeTrackerExtension: false,
  isMMSEnabled: false,
  isInboxEnabled: false,
  isWorkFlowIcon: false,
  isWorkflowtext: "Webhook",
  productId: "100",
  workFlowIconInTemplates: false,
  isWorkflowTableFormat: false,
  isOtpPopupNeeded: true,
  planDetails: {},
  trialPlanContent: {
    header: "Serve your customers",
    highlightedText: "where they are!",
    mainContent:
      "Enable all your messaging channels at one place with MessagingCentral application. An OmniChannel messaging solution helps users to Send / Receive / Automate / Manage the messages from all messaging services at one place.",
    point1: "Automated SMS",
    point2: "Customized Inbox",
    point3: "Conversation View",
  },
  showEnablePageContent: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getDefaultModuleForAGivenModule: function (moduleName) {
    return new Promise((resolve, reject) => {
      resolve(moduleName);
    });
  },
  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  pricingLink: function (rightServiceName, leftId, rightId) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  checkIsLicensed: function (fetchInstalledServices, appProperties, getAllTextSMSServices) {
    return new Promise((resolve, reject) => {
      getAllTextSMSServices(appProperties)
        .then((response) => {
          appProperties.apps = response?.data?.apps;
          appProperties.leftServiceClientDetails = response?.data?.serviceInfo;
          fetchInstalledServices(appProperties)
            .then((integrationsResponse) => {
              console.log(integrationsResponse, ">>>>>>>>fetchInstalledServices >>>>>>>>");
              if (integrationsResponse?.data) {
                integrationsResponse.data.apps = response?.data?.apps;
                integrationsResponse.data.leftServiceClientDetails = response?.data?.serviceInfo;
              }
              resolve(integrationsResponse);
            })
            .catch(function (err) {
              console.log("errrrrr", err);
              reject();
            });
        })
        .catch(function (err) {
          console.log("errrrrr", err);
          reject();
        });
    });
  },
  checkCurrentUserIsLicensed: function (rightServiceName, leftId, rightId) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  checkIsAlreadyInstalled: function (fetchData) {
    return new Promise((resolve, reject) => {
      console.log(" its coming here buddddy ");
      resolve();
    });
  },
  checkIsBothServicesAuthorized: function (fetchAuthorizationData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchAuthorizationData(appProperties).then((resonse) => {
        resolve(resonse);
      });
    });
  },
  init: function () {
    return new Promise((resolve, reject) => {
      console.log(" its coming here buddddy :::::: DEFAULT >>>>>> INIT");
      resolve();
    });
  },

  save: function (data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  saveApplicationToken: function (data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {});
  },
  getSavedNumbers: function () {
    return new Promise((resolve, reject) => {});
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  delete: function (key) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  getUserData: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      console.log(urlParams.get("selectedIds"), "associated id");
      resolve(urlParams.get("selectedIds"));
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource"));
    });
  },
  getAssociatedObjectTypeInPlural: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource"));
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  fetchContact: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  doAfterAuthorize: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  doCompleteInstallationProcess: function (fetchData, fetchCompleteInstalltionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchCompleteInstalltionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties, templateId) {
    return new Promise((resolve, reject) => {
      fetchWebhookNotifications(appProperties, templateId).then((response) => {
        console.log("res CRM response", response);
        resolve(response);
      });
    });
  },
  getSubscriptionId: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  saveSubscriptionId: function (subscriptionIdFromServer) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  doExtensionSpecificWorks: function (subscriptionIdFromServer) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  insertContent: function (resultAreaInput) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  doAuthorization: function (integId, hash, userId) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  fetchDefaultFilterOptions: function (moduleName) {
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  },
  setWebhook: function (webhookUrl, webhookId, appProperties) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getWebhook: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    return new Promise((resolve, reject) => {
      var redirectUrl = window.location.search;
      resolve(redirectUrl);
    });
  },
  fetchContactsCount: function (fetchFilterInfoByFilterId, appProperties, integId, module) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      resolve(settingsObj);
    });
  },
  defaultSettingValue: {
    emailNotification: {
      sendEmail: true,
      recordOwner: true,
      accountOwner: true,
    },
    loggingPreference: "notes",
  },
  getApplicationToken: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  },
};
