import { useContext, useState } from "react";
import BalanceCredits from "../BalanceCredits";
import { RegisterChargebee } from "../../../pages/utils/ChargebeeRegistration";
import { AppContext } from "../../../context/AppContext";
import { Chargebee_CHARGE_NAME } from "../../../constants/AppConstants";

const SideBarCredits = ({ item }) => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [chargeBeePlan, setChargeBeePlan] = useState(Chargebee_CHARGE_NAME);

  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  return (
    <div data-cb-type="checkout" data-cb-item-0={chargeBeePlan} data-cb-item-0-quantity="1" onMouseEnter={registerAgain}>
      <BalanceCredits
        setBalanceBackground={() => {}}
        setShowOneTimeCharge={() => {}}
        setChargeBeePlan={setChargeBeePlan}
        chargeBeePlan={chargeBeePlan}
        handleCreditBalance={() => {}}
        sideBar={true}
      />
    </div>
  );
};
export default SideBarCredits;
